<template>
  <div class="main-bg">
    <br />
    <!-- title -->
    <h1 style="color: rgb(248, 192, 7)">
      Ünüvar, B2B portalına hoş geldiniz...
    </h1>
    <!-- //title -->

    <div class="sub-main-w3">
      <div class="image-style"></div>
      <!-- vertical tabs -->
      <div class="vertical-tab">
        <div id="section1" class="section-w3ls">
          <input type="radio" name="sections" id="option1" checked />
          <label for="option1" class="icon-left-w3pvt"
            ><span class="fa fa-user-circle" aria-hidden="true"></span>Ünüvar
            A.Ş. | B2B</label
          >
          <article>
            <validation-observer ref="loginForm" #default="{ invalid }">
              <form action="#" @submit.prevent="login">
                <h3 class="legend">Ünüvar Elektronik A.Ş.</h3>
                <div class="inputa">
                  
                  
                  <validation-provider
                 
                    #default="{ errors }"
                    name="Email"
                    vid="email"
                    rules="required|email"
                  >
                    <input
                      id="login-email"
                      v-model="userEmail"
                      name="login-email"
                      :state="errors.length > 0 ? false : null"
                      required=""
                      placeholder="kullanici@mail.com"
                  /></validation-provider>
                </div>
                <div class="inputa">
                 
                  <validation-provider
                   
                    name="Password"
                    vid="password"
                    rules="required"
                  >
                    <input
                      id="login-password"
                      v-model="password"
                     
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Parola"
                      required=""
                  /></validation-provider>
                </div>
                <button type="submit" class="btn submit" :disabled="invalid">
                  Lütfen giriş yapınız!
                </button>

                <a
                  href="http://unuvar.com"
                  class="fa fa-home fa-2x bottom-text-w3ls"
                >
                  Ana Sayfaya Geri Dön...!</a
                >
              </form>
            </validation-observer>
          </article>
        </div>

        <div id="section2" class="section-w3ls">
          <input type="radio" name="sections" id="option3" />
          <label for="option3" class="icon-left-w3pvt"
            ><span class="fa fa-lock" aria-hidden="true"></span>Şifremi
            Unuttum!</label
          >
          <article>
            <validation-observer ref="simpleRules">
              <form action="#" method="post" @submit.prevent="validationForm">
                <h3 class="legend last">Şifremi Yenile</h3>
                <p class="para-style">
                  E-posta adresinizi aşağıya girin, talimatları içeren bir
                  e-posta alacaksınız...
                </p>
                <p class="para-style-2">
                  <strong>Yardıma mı ihtiyacınız var?</strong> Şifre yenileme
                  hakkında
                  <a href="http://unuvar.com">daha fazla bilgi edinin...</a>
                </p>
                <div class="inputa" >
                  
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <input 
                      id="forgot-password-email"
                      v-model="userEmail"
                      :state="errors.length > 0 ? false : null"
                      type="email"
                      placeholder="Email"
                      name="forgot-password-email"
                      
                    />
                    
                  </validation-provider>
                </div>
                
                <button type="submit" class="btn submit last-btn">
                  Şifremi Yenile
                </button>
              </form>
            </validation-observer>
          </article>
        </div>
      </div>
      <!-- //vertical tabs -->
      <div class="clear"></div>
    </div>

    <!-- copyright -->
    <div class="copyright">
      <h2>
        Ünüvar Elektronik A.Ş. B2B | Design by
        <a href="http://unuvar.com" target="_blank">Ünüvar Elektronik A.Ş.</a>
      </h2>
    </div>
    <!-- //copyright -->
  </div>
</template>

<script>
/* eslint-disable global-require */
import "@/assets/css/style.css";
import "@/assets/css/font-awesome.min.css";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
  
  
} from "bootstrap-vue";
import useJwt from "@/auth/jwt/useJwt";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "",
      userEmail: "",
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      required,
      email,
      addDealerRequestModel: {},
      sideImg: require("@/assets/images/pages/forgot-password-v2.svg"),
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/forgot-password-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then((success) => {
        if (success) {
          useJwt
            .login({
              email: this.userEmail,
              password: this.password,
            })
            .then((response) => {
              const initialAbility = [
                {
                  action: "read",
                  subject: "Auth",
                },
                {
                  action: "manage",
                  subject: "all",
                },
              ];
              response.data.result.ability = initialAbility;

              useJwt.setToken(response.data.result.token);

              localStorage.setItem(
                "userData",
                JSON.stringify(response.data.result)
              );
              console.log("Burası Okey");
              this.$ability.update(response.data.result.ability);
              console.log("Burası Okey 2");
              console.log(response.data.result.role);
              this.$router
                .push(getHomeRouteForLoggedInUser(response.data.result.role))
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: `${response.data.result.dealerErpName}`,
                      icon: "CoffeeIcon",
                      variant: "success",
                      text: `Başarılı bir şekilde giriş yaptınız.`,
                    },
                  });
                })
                .catch((error) => {
                  console.log("Hata");
                  console.log(error);
                  this.$refs.loginForm.setErrors(error);
                });
            })
            .catch((error) => {
              console.log(error);
              if (error.response.data.isError) {
                this.$toast({
                  component: ToastificationContent,
                  position: "bottom-right",
                  props: {
                    title: `Hatalı Giriş`,
                    icon: "DangerIcon",
                    variant: "danger",
                    text:
                      error.response.data.responseException.exceptionMessage,
                  },
                });
              }
            });
        }
      });
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.show = true;
          this.addDealerRequestModel = {
            email: this.userEmail,
          };
          this.$http
            .post("Auth/ForgotPassword", this.addDealerRequestModel)
            .then((response) => {
              this.show = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Başarılı",
                  icon: "UserIcon",
                  text: response.data.message,
                  variant: "success",
                },
              });
              this.addDealerRequestModel = {};
            })
            .catch((error) => {
              console.log(error);
              this.show = false;
              this.$swal({
                title: "Hata!",
                text: error.response.data.responseException.exceptionMessage,
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                confirmButtonText: "Kapat",
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
